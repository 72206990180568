import { makeAutoObservable } from 'mobx'
import jwtDecode from 'jwt-decode'
import { clearLSValue } from 'utils/localStorage'
import { getLSValue } from 'utils/localStorage'
class Session {
  id = null
  firstName = ''
  lastName = ''
  token = ''
  email = ''
  permissions = []
  widgets = []
  activeAccount = {}
  accounts = []
  validFrom = ''
  validTo = ''
  language = ''
  activeEnergyTab = 1
  activeBillingEnergyTab = 1
  activeSensorId = 0
  activePeriod = 'week'
  companyLogo = ''

  constructor() {
    makeAutoObservable(this)
  }
  setActiveEnergyTab(activeEnergyTab) {
    this.activeEnergyTab = activeEnergyTab
  }
  setActiveBillingEnergyTab(activeBillingEnergyTab) {
    this.activeBillingEnergyTab = activeBillingEnergyTab
  }
  setActiveSensorId(sensorId) {
    this.activeSensorId = sensorId
  }
  setActivePeriod(period) {
    this.activePeriod = period
  }
  get user() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      permissions: this.permissions,
      widgets: this.widgets,
      token: this.token,
      validFrom: this.validFrom,
      validTo: this.validTo,
      accounts: this.accounts,
      activeAccount: this.activeAccount,
      language: this.language,
    }
  }

  saveToken(token) {
    try {
      const decodedToken = jwtDecode(token)
      const { Id, FirstName, LastName, Email, Permissions, Widgets } = decodedToken.user
      const { ValidFrom, ValidTo } = decodedToken.account
      const  logo  = decodedToken.companyLogo

      const permissions = Permissions.map((item) => item.Name)
      const widgets = Widgets.map((item) => item.Name)

      this.id = Id
      this.firstName = FirstName
      this.lastName = LastName
      this.email = Email
      this.permissions = permissions
      this.widgets = widgets
      this.token = token
      this.validFrom = ValidFrom
      this.validTo = ValidTo
      this.activeAccount = decodedToken.account
      this.language = getLSValue('language') || 'de'
      this.companyLogo = logo
    } catch (err) {
      console.error(err)
      this.logOut()
    }
  }

  changeLanguage = (lang) => {
    this.language = lang
  }

  setUserAccounts(accounts) {
    this.accounts = accounts
  }

  logOut() {
    this.token = ''
    this.activeAccount = {}
    this.accounts = []
    clearLSValue('token')
    clearLSValue('tokens')
  }
}

export default Session
